import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flex, StyledFlex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { FlexBox } from './helpers/FlexBox';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Flex" title="Flex" mdxType="PageTitle" />
    <h2 {...{
      "id": "container-row"
    }}>{`Container row`}</h2>
    <h3 {...{
      "id": "justify-content-space-between-reverse"
    }}>{`Justify content space between, reverse`}</h3>
    <Playground __position={1} __code={'<Flex reverse direction=\"row\" justifyContent=\"space-between\">\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40 + i * 10} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex reverse direction="row" justifyContent="space-between" mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40 + i * 10} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-space-around"
    }}>{`Justify content space around`}</h3>
    <Playground __position={2} __code={'<Flex direction=\"row\" justifyContent=\"space-around\">\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40 + i * 10} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex direction="row" justifyContent="space-around" mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40 + i * 10} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-space-evenly"
    }}>{`Justify content space evenly`}</h3>
    <Playground __position={3} __code={'<Flex direction=\"row\" justifyContent=\"space-evenly\">\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40 + i * 10} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex direction="row" justifyContent="space-evenly" mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40 + i * 10} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-center-align-items-center"
    }}>{`Justify content center, align items center`}</h3>
    <Playground __position={4} __code={'<Flex alignItems=\"center\" direction=\"row\" justifyContent=\"center\">\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40 + i * 10} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="center" direction="row" justifyContent="center" mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40 + i * 10} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-start-align-items-start"
    }}>{`Justify content start, align items start`}</h3>
    <Playground __position={5} __code={'<Flex alignItems=\"flex-start\" direction=\"row\" justifyContent=\"flex-start\">\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40 + i * 10} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="flex-start" direction="row" justifyContent="flex-start" mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40 + i * 10} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-end-align-items-end"
    }}>{`Justify content end, align items end`}</h3>
    <Playground __position={6} __code={'<Flex alignItems=\"flex-end\" direction=\"row\" justifyContent=\"flex-end\">\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40 + i * 10} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="flex-end" direction="row" justifyContent="flex-end" mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40 + i * 10} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "align-content-center-wrap"
    }}>{`Align content center, wrap`}</h3>
    <Playground __position={7} __code={'<Flex wrap alignContent=\"center\" direction=\"row\" style={{ height: \'320px\' }}>\n  <Looper\n    end={30}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex wrap alignContent="center" direction="row" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={30} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "align-content-space-between-wrap"
    }}>{`Align content space between, wrap`}</h3>
    <Playground __position={8} __code={'<Flex\n  wrap\n  alignContent=\"space-between\"\n  direction=\"row\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={30}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex wrap alignContent="space-between" direction="row" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={30} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h2 {...{
      "id": "container-column"
    }}>{`Container column`}</h2>
    <h3 {...{
      "id": "justify-content-space-between-reverse-1"
    }}>{`Justify content space between, reverse`}</h3>
    <Playground __position={9} __code={'<Flex\n  reverse\n  direction=\"column\"\n  justifyContent=\"space-between\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40 + i * 10}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex reverse direction="column" justifyContent="space-between" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40 + i * 10} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-space-around-1"
    }}>{`Justify content space around`}</h3>
    <Playground __position={10} __code={'<Flex\n  direction=\"column\"\n  justifyContent=\"space-around\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40 + i * 10}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex direction="column" justifyContent="space-around" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40 + i * 10} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-space-evenly-1"
    }}>{`Justify content space evenly`}</h3>
    <Playground __position={11} __code={'<Flex\n  direction=\"column\"\n  justifyContent=\"space-evenly\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40 + i * 10}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex direction="column" justifyContent="space-evenly" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40 + i * 10} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-center-align-items-center-1"
    }}>{`Justify content center, align items center`}</h3>
    <Playground __position={12} __code={'<Flex\n  alignItems=\"center\"\n  direction=\"column\"\n  justifyContent=\"center\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40 + i * 10}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="center" direction="column" justifyContent="center" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40 + i * 10} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-start-align-items-start-1"
    }}>{`Justify content start, align items start`}</h3>
    <Playground __position={13} __code={'<Flex\n  alignItems=\"flex-start\"\n  direction=\"column\"\n  justifyContent=\"flex-start\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40 + i * 10}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="flex-start" direction="column" justifyContent="flex-start" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40 + i * 10} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "justify-content-end-align-items-end-1"
    }}>{`Justify content end, align items end`}</h3>
    <Playground __position={14} __code={'<Flex\n  alignItems=\"flex-end\"\n  direction=\"column\"\n  justifyContent=\"flex-end\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={4}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40 + i * 10}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="flex-end" direction="column" justifyContent="flex-end" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={4} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40 + i * 10} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "align-content-center-wrap-1"
    }}>{`Align content center, wrap`}</h3>
    <Playground __position={15} __code={'<Flex\n  wrap\n  alignContent=\"center\"\n  direction=\"column\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={10}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex wrap alignContent="center" direction="column" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={10} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "align-content-space-between-wrap-1"
    }}>{`Align content space between, wrap`}</h3>
    <Playground __position={16} __code={'<Flex\n  wrap\n  alignContent=\"space-between\"\n  direction=\"column\"\n  style={{ height: \'320px\' }}\n>\n  <Looper\n    end={10}\n    render={i => (\n      <Flex key={i}>\n        <FlexBox height={40} width={40}>\n          {i}\n        </FlexBox>\n      </Flex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex wrap alignContent="space-between" direction="column" style={{
        height: '320px'
      }} mdxType="Flex">
    <Looper end={10} render={i => <Flex key={i} mdxType="Flex">
          <FlexBox height={40} width={40} mdxType="FlexBox">
            {i}
          </FlexBox>
        </Flex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "styled-flex-grow"
    }}>{`Styled flex grow`}</h3>
    <Playground __position={17} __code={'<Flex wrap direction=\"row\">\n  <Looper\n    end={10}\n    render={i => (\n      <StyledFlex flexGrow={i} key={i}>\n        <FlexBox height={40} width=\"100%\">\n          {i}\n        </FlexBox>\n      </StyledFlex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex wrap direction="row" mdxType="Flex">
    <Looper end={10} render={i => <StyledFlex flexGrow={i} key={i} mdxType="StyledFlex">
          <FlexBox height={40} width="100%" mdxType="FlexBox">
            {i}
          </FlexBox>
        </StyledFlex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h3 {...{
      "id": "styled-flex-shorthand"
    }}>{`Styled flex shorthand`}</h3>
    <Playground __position={18} __code={'<Flex direction=\"row\" wrap={false}>\n  <Looper\n    end={10}\n    render={i => (\n      <StyledFlex flex={`${i} 0 40px`} key={i}>\n        <FlexBox height={40} width=\"100%\">\n          {i}\n        </FlexBox>\n      </StyledFlex>\n    )}\n    start={0}\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      StyledFlex,
      Looper,
      PageTitle,
      FlexBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex direction="row" wrap={false} mdxType="Flex">
    <Looper end={10} render={i => <StyledFlex flex={`${i} 0 40px`} key={i} mdxType="StyledFlex">
          <FlexBox height={40} width="100%" mdxType="FlexBox">
            {i}
          </FlexBox>
        </StyledFlex>} start={0} mdxType="Looper" />
  </Flex>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Flex} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      